<template>
    <v-container
        v-resize="onResize"
        fluid
        class="overflow-y-hidden  mx-0 px-0 py-0 mt-3"
        ref="container"
    >
        <v-card flat>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="filters"
                    class="elevation-0"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    disable-pagination
                    hide-default-footer
                    height="100%"
                    fixed-header
                >
                    <template v-slot:top>
                        <v-row
                            no-gutter
                            class="mt-0 mx-0 pt-4 pb-0 pb-4 align-center"
                            style="background-color: #eeeeee"
                        >
                            <h1 class="ml-4 mr-4">
                                ENGINEERING HISTORY
                            </h1>
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                class="custom-datepicker"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="dateRangeText"
                                        label="Date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :style="{ 'max-width': '250px' }"
                                        class="pt-1"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="dates"
                                    range
                                    scrollable
                                    :max="maxDate"
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="menu = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        :loading="loading"
                                        @click="loadData"
                                    >
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-row>
                    </template>
                    <!--HEADERS-->
                    <template v-slot:[`header.quote.name`]="{ header }">
                        <v-combobox
                            v-model="quotesToFilter"
                            :items="quotesForUser"
                            :label="header.text"
                            flat
                            solo
                            background-color="#eeeeee"
                            multiple
                            item-text="name"
                            item-value="id"
                            hide-details
                        >
                            <template v-slot:selection="{ index }">
                                <div v-if="index === 0" class="mr-6">
                                    <span>Quotes</span>
                                </div>
                                <v-badge
                                    v-if="index === 0"
                                    :content="quotesToFilter.length"
                                    :value="quotes"
                                    color="primary"
                                    overlap
                                    class="mt-n2"
                                >
                                </v-badge>
                            </template>
                        </v-combobox>
                    </template>
                    <template v-slot:[`header.reference`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="referenceToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>
                    <template v-slot:[`header.category`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="categoryToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>
                    <template v-slot:[`header.engineer`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="engineerToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>
                    <template v-slot:[`header.quote.promiseDate`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="promiseDateToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>
                    <template v-slot:[`header.quote.clientId`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="clientToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>
                    <template v-slot:[`header.quote.userId`]="{ header }">
                        <v-combobox
                            v-model="usersToFilter"
                            :items="quotesUsers"
                            :label="header.text"
                            flat
                            solo
                            multiple
                            item-text="name"
                            item-value="id"
                            :return-object="false"
                            hide-details
                            background-color="#eeeeee"
                        >
                            <template v-slot:selection="{ index }">
                                <div v-if="index === 0" class="mr-6">
                                    <span>Users</span>
                                </div>
                                <v-badge
                                    v-if="index === 0"
                                    :content="usersToFilter.length"
                                    :value="quotes"
                                    color="primary"
                                    overlap
                                    class="mt-n2"
                                >
                                </v-badge>
                            </template>
                        </v-combobox>
                    </template>
                    <template v-slot:[`header.quote.status`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="statusToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>
                    <template v-slot:[`header.quote.requestDate`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="requestDateToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>

                    <template
                        v-slot:[`header.quote.invitationDate`]="{
                            header,
                        }"
                    >
                        <v-text-field
                            :label="header.text"
                            v-model="invitationDateToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>
                    <template v-slot:[`header.engineeringDate`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="engineeringDateToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>
                    <template v-slot:[`header.lastUpdate`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="lastUpdateToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>

                    <!-- Items -->
                    <template v-slot:[`item.reference`]="{ item }">
                        <div dense class="pt-2">
                            {{ item.reference }}
                        </div>
                    </template>

                    <template v-slot:[`item.quote.clientId`]="{ item }">
                        <div class="my-4">
                            {{ getClientName(item.quote.clientId) }}
                        </div>
                    </template>
                    <template v-slot:[`item.engineer`]="{ item }">
                        <div class="my-4">
                            {{ getUserName(item.engineer) }}
                        </div>
                    </template>
                    <template v-slot:[`item.quote.status`]="{ item }">
                        <div class="my-4">
                            {{ defineStatus(item.quote.status) }}
                        </div>
                    </template>

                    <template v-slot:[`item.quote.userId`]="{ item }">
                        <div class="my-0">
                            <div>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <p
                                            class="my-0 font-weight-medium"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            {{
                                                item.quote.mainContact
                                                    ? item.quote.mainContact
                                                          .name
                                                    : ''
                                            }}
                                        </p>
                                    </template>
                                    <span>Account M.</span>
                                </v-tooltip>
                            </div>
                            <div>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <p
                                            class="my-0 font-weight-medium"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            {{
                                                item.quote.estimatorInfo
                                                    ? item.quote.estimatorInfo
                                                          .name
                                                    : ''
                                            }}
                                        </p>
                                    </template>
                                    <span>Estimator</span>
                                </v-tooltip>
                            </div>
                        </div>
                    </template>

                    <template v-slot:[`item.quote.requestDate`]="{ item }">
                        <div dense class="pt-2">
                            {{
                                item.quote.requestDate &&
                                item.quote.requestDate._seconds
                                    ? formatDate(
                                          item.quote.requestDate._seconds
                                      )
                                    : ''
                            }}
                        </div>
                    </template>
                    <template v-slot:[`item.lastUpdate`]="{ item }">
                        <div dense class="pt-2">
                            {{
                                item.lastUpdate
                                    ? item.lastUpdate.value.substring(0, 10)
                                    : ''
                            }}
                        </div>
                    </template>
                    <template v-slot:[`item.engineeringDate`]="{ item }">
                        <div dense class="pt-2">
                            {{
                                item.engineeringDate &&
                                item.engineeringDate._seconds
                                    ? formatDate(item.engineeringDate._seconds)
                                    : item.engineeringDate &&
                                      item.engineeringDate.seconds
                                    ? formatDate(item.engineeringDate.seconds)
                                    : item.engineeringDate
                                    ? item.engineeringDate
                                    : ''
                            }}
                        </div>
                    </template>
                    <template v-slot:[`item.preDesign`]="{ item }">
                        <div dense class="pt-2">
                            <v-tooltip
                                v-if="item && item.preDesign"
                                top
                                class="custom-datepicker"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <span
                                        class="pa-1 ma-0 mt-1 ml-1 font-weight-bold text-decoration-underline"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click.stop="openFile(item)"
                                        style="font-size: 11px; color: #2b81d6; cursor: pointer;"
                                        >{{
                                            formatName(
                                                item.preDesign[
                                                    item.preDesign.length - 1
                                                ].name
                                            )
                                        }}</span
                                    >
                                </template>
                                <span>{{
                                    item.preDesign[item.preDesign.length - 1]
                                        .name
                                        ? item.preDesign[
                                              item.preDesign.length - 1
                                          ].name
                                        : ''
                                }}</span>
                            </v-tooltip>
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import moment from 'moment'
import API from '@/services/api'
// import _ from 'lodash'
import { openFile } from '@/services/storage/openFile.js'
export default {
    name: 'EngineeringHistory',
    components: {},
    data() {
        return {
            height: 0,
            headers: [
                {
                    text: 'Quotes',
                    value: 'quote.name',
                    align: 'center',
                    sortable: false,
                    width: 95,
                },
                {
                    text: 'Scopes',
                    value: 'reference',
                    align: 'center',
                    sortable: false,
                    width: 100,
                },
                {
                    text: 'Scope Type',
                    value: 'category',
                    align: 'center',
                    width: 125,
                    sortable: false,
                },
                {
                    text: 'Client',
                    value: 'quote.clientId',
                    align: 'center',
                    width: 100,
                    sortable: false,
                },
                {
                    text: 'Engineer',
                    value: 'engineer',
                    align: 'center',
                    width: 100,
                    sortable: false,
                },
                {
                    text: 'Users',
                    value: 'quote.userId',
                    align: 'center',
                    width: 120,
                    sortable: false,
                },
                {
                    text: 'Status',
                    value: 'quote.status',
                    align: 'center',
                    sortable: false,
                    width: 100,
                },
                {
                    text: 'Last Update',
                    value: 'lastUpdate',
                    align: 'center',
                    sortable: false,
                    width: 120,
                },
                {
                    text: 'Eng Due Date',
                    value: 'engineeringDate',
                    align: 'center',
                    sortable: false,
                    width: 120,
                },
                {
                    text: 'Pre-Designs',
                    value: 'preDesign',
                    sortable: false,
                    width: 110,
                    align: 'center',
                },
            ],
            quotesToFilter: '',
            quotesForUser: [],
            referenceToFilter: '',
            categoryToFilter: '',
            promiseDateToFilter: '',
            invitationDateToFilter: '',
            engineeringDateToFilter: '',
            clientToFilter: '',
            engineerToFilter: '',
            accountManagerToFilter: '',
            lastUpdateToFilter: '',
            usersToFilter: [],
            estimatorToFilter: '',
            statusToFilter: '',
            requestDateToFilter: '',
            loading: false,
            costs: [],
            quotesUsers: [],
            menu: false,
            dates: [],
            quotesUsersId: [],
            maxDate: '',
            quotes: [],
        }
    },
    updated() {
        this.onResize()
    },
    computed: {
        dateRangeText() {
            return this.dates.join(' ~ ')
        },
        filters() {
            let conditions = []

            if (this.quotesToFilter) {
                conditions.push(this.filterForQuotes)
            }
            if (this.referenceToFilter) {
                conditions.push(this.filterForReference)
            }
            if (this.categoryToFilter) {
                conditions.push(this.filterForCategory)
            }
            if (this.engineeringDateToFilter) {
                conditions.push(this.filterForEngineeringDate)
            }
            if (this.lastUpdateToFilter) {
                conditions.push(this.filterForLastUpdate)
            }
            if (this.clientToFilter) {
                conditions.push(this.filterForClient)
            }
            if (this.engineerToFilter) {
                conditions.push(this.filterForEngineer)
            }
            if (this.usersToFilter) {
                conditions.push(this.filterForUsers)
            }
            if (this.estimatorToFilter) {
                conditions.push(this.filterForEstimator)
            }
            if (this.statusToFilter) {
                conditions.push(this.filterForStatus)
            }
            if (conditions.length > 0) {
                return this.costs.filter(cost => {
                    return conditions.every(condition => {
                        return condition(cost)
                    })
                })
            }
            this.sort()
            return this.costs
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.resourceId = this.$route.query.resourceId
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            const {
                data: { clients },
            } = await API.getLiteClients()
            this.clients = clients
            const {
                data: { settings },
            } = await API.getSettings()
            this.settings = settings
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            this.companyId = this.user.companyId
            // this.engineeringCostPermission = user.permissions.includes(
            //     'engineeringCost'
            // )
            //set initial dates
            let currentDate = new Date()
            let date1 = new Date(currentDate)
            date1.setMonth(date1.getMonth() - 1)
            date1.setHours(0, 0, 0, 0)
            this.dates[0] = date1.toISOString().split('T')[0]
            this.dates[1] = currentDate.toISOString().split('T')[0]
            this.maxDate = this.dates[1]
            await this.loadData()
        } catch (error) {
            console.log(error)
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async openFile(item) {
            try {
                if (
                    this.companyId &&
                    item.quoteId &&
                    (item.id || item.document_id)
                ) {
                    await openFile(
                        item.preDesign[item.preDesign.length - 1].name,
                        `${this.companyId}/quotes/${
                            item.quoteId
                        }/costs/${item.id || item.document_id}/preDesigns`
                    )
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        formatName(name) {
            const version = name ? name.split(' ').pop() : ''
            return version
        },
        filterForQuotes(item) {
            if (item && item.quote && this.quotesToFilter.length > 0) {
                return this.quotesToFilter.some(
                    quote => quote == item.quote.name
                )
            } else {
                return true
            }
        },
        filterForUsers(item) {
            if (item && item.quote && this.usersToFilter.length > 0) {
                let validation = this.usersToFilter.every(field =>
                    [item.quote.userId, item.quote.estimatorId].includes(field)
                )
                return validation
            } else {
                return true
            }
        },
        filterForReference(item) {
            if (item.reference) {
                return item.reference
                    .toLowerCase()
                    .includes(this.referenceToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForCategory(item) {
            if (item.category) {
                return item.category
                    .toLowerCase()
                    .includes(this.categoryToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForClient(item) {
            if (item.quote.clientId) {
                return this.getClientName(item.quote.clientId)
                    .toLowerCase()
                    .includes(this.clientToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForEngineer(item) {
            const name = item.engineer
                ? this.getUserName(item.engineer)
                : this.getUserName(
                      item.preDesign[item.preDesign.length - 1].engineer
                  )
            if (name) {
                return name
                    .toLowerCase()
                    .includes(this.engineerToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForAccountManager(item) {
            if (item.quote.userId) {
                return this.getUserName(item.quote.userId)
                    .toLowerCase()
                    .includes(this.accountManagerToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForCosts(item) {
            if (item.engineering) {
                return item.engineering
            } else {
                return false
            }
        },
        filterForEstimator(item) {
            if (item.quote.estimatorId) {
                return this.getUserName(item.quote.estimatorId)
                    .toLowerCase()
                    .includes(this.estimatorToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForStatus(item) {
            if (item.quote.status) {
                return this.defineStatus(item.quote.status)
                    .toLowerCase()
                    .includes(this.statusToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForRequestDate(item) {
            if (item.quote.requestDate) {
                const date = this.formatDate(
                    item.quote.requestDate._seconds
                        ? item.quote.requestDate._seconds
                        : item.quote.requestDate.seconds
                        ? item.quote.requestDate.seconds
                        : 0
                )
                return date.includes(this.requestDateToFilter)
            } else {
                return false
            }
        },
        filterForEngineeringDate(item) {
            if (item.engineeringDate && item.engineeringDate._seconds) {
                const date = this.formatDate(
                    item.engineeringDate._seconds
                        ? item.engineeringDate._seconds
                        : item.engineeringDate.seconds
                        ? item.engineeringDate.seconds
                        : 0
                )
                return date.includes(this.engineeringDateToFilter)
            } else {
                return false
            }
        },
        filterForLastUpdate(item) {
            if (item.lastUpdate) {
                const date = item.lastUpdate.value.substring(0, 10)
                return date.includes(this.lastUpdateToFilter)
            } else {
                return false
            }
        },
        async loadData() {
            try {
                this.loading = true
                this.menu = false
                let date1 = new Date(this.dates[0]).getTime()
                let date2 = new Date(this.dates[1]).getTime()
                if (date1 > date2) {
                    date1 = this.dates[1]
                    date2 = this.dates[0]
                } else {
                    date1 = this.dates[0]
                    date2 = this.dates[1]
                }
                let {
                    data: { costs },
                } = await API.getGeneratedPreDesigns({
                    startDate: date1,
                    endDate: date2,
                })
                costs.forEach(cost => {
                    if (cost.quote && cost.quote.userId) {
                        cost.quote.mainContact = this.users.find(
                            user => cost.quote.userId == user.id
                        )
                    }
                    if (cost.quote && cost.quote.estimatorId) {
                        cost.quote.estimatorInfo = this.users.find(
                            user => cost.quote.estimatorId == user.id
                        )
                    }
                    if (cost.quote.estimatorId) {
                        this.quotesUsersId.push(cost.quote.estimatorId)
                    }
                    if (cost.quote.userId) {
                        this.quotesUsersId.push(cost.quote.userId)
                    }
                    if (cost.quote) {
                        this.quotes.push(cost.quote)
                    }
                })
                this.quotesUsersId = [...new Set(this.quotesUsersId)]
                this.quotesUsersId.forEach(user =>
                    this.quotesUsers.push({
                        id: user,
                        name: this.getUserName(user),
                    })
                )
                this.costs = costs
                const setQuotes = new Set()
                this.costs.forEach(cost => {
                    if (cost.quote) {
                        setQuotes.add(cost.quote.name)
                    }
                })
                this.quotesForUser = Array.from(setQuotes)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.sort()
                this.loading = false
            }
        },
        defineStatus(status) {
            if (status == 'OPEN') {
                return 'Bidding'
            } else if (status == 'APPROVAL') {
                return 'Approval'
            } else if (status == 'SENT') {
                return 'Follow Up'
            } else if (status == 'MODIFIED') {
                return 'Modify'
            } else if (status == 'AWARDED') {
                return 'Awarded'
            } else {
                return ''
            }
        },
        sort() {
            this.costs.sort(
                (a, b) =>
                    this.formatISOToNumeric(a.lastUpdate.value) -
                    this.formatISOToNumeric(b.lastUpdate.value)
            )
        },
        formatDate(seconds = undefined) {
            return `${moment.unix(seconds).format('YYYY-MM-DD')}`
        },
        formatDate3(seconds) {
            return moment.unix(seconds).format('YYYYMMDD')
        },
        formatISOToNumeric(isoString) {
            return isoString.slice(0, 10).replace(/-/g, '')
        },
        formatDate2(date) {
            if (!date) return ''

            if (typeof date === 'object' && (date._seconds || date.seconds)) {
                return moment
                    .unix(date._seconds || date.seconds)
                    .format('YYYY-MM-DD')
            } else if (
                typeof date === 'string' &&
                moment(date, moment.ISO_8601, true).isValid()
            ) {
                return moment(date).format('YYYY-MM-DD')
            } else {
                console.warn('Invalid date format:', date)
                return ''
            }
        },

        getUserName(id) {
            if (this.users.length > 0 && id) {
                return this.users.find(user => user.id == id)?.name
            } else {
                return ''
            }
        },
        getClientName(id) {
            if (this.clients.length > 0) {
                return this.clients.find(client => client.id == id)?.name
            } else {
                return ''
            }
        },
        onResize() {
            this.height = window.innerHeight - 112
            this.heightPopUp = window.innerHeight - 62
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
.v-data-table__wrapper thead tr {
    position: sticky;
    top: 0;
    z-index: 10;
}
thead th {
    position: sticky;
    z-index: 1;
    background-color: #eeeeee !important;
}
.custom-datepicker {
    z-index: 100 !important; /* Ajusta según sea necesario */
}
</style>
